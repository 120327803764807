
import React from 'react';
import SmallMenu from '../Home/Mobile/Menu';
import { Alert, Container, Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

function PasswordReset() {
    let [searchParams] = useSearchParams();

    const [newPassword, setNewPassword] = React.useState('');
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [updated, setUpdated] = React.useState(false);

    const onPasswordReset = async (e: any) => {
        e.preventDefault();

        // get the toke from the url
        const passwordResetToken = searchParams.get('token');

        const response = await fetch(`${process.env.REACT_APP_API_HOST}/password-reset?token=${passwordResetToken}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                newPassword,
            }),
        });

        const data = await response.json();

        if (data.error) {
            setUpdated(false);
            setErrorMessage(data.error);
        } else {
            setUpdated(true);
            setErrorMessage('');
        }
    }

    return (
        <div>
            <SmallMenu />
            <Container>

            </Container>
            <div style={{ paddingTop: '15vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '80%' }}>

                    <h2 className='text-light'>Password Reset</h2>
                    <Alert variant="danger" style={{ opacity: errorMessage.length > 0 ? '100' : '0' }}>
                        {errorMessage}
                    </Alert>

                    <Alert variant="success" style={{ display: updated ? 'block' : 'none' }}>
                        Password updated successfully
                    </Alert>

                    <Form onSubmit={onPasswordReset}>
                        <Form.Group controlId="formNewPassword">
                            <Form.Label className='text-light'>New Password</Form.Label>
                            <Form.Control required type="password" placeholder="Enter new password" isInvalid={newPassword !== confirmNewPassword} onChange={(event) => setNewPassword(event.target.value)} />
                        </Form.Group>

                        <Form.Group controlId="formConfirmNewPassword">
                            <Form.Label className='text-light'>Confirm New Password</Form.Label>
                            <Form.Control required type="password" placeholder="Confirm new password" isInvalid={newPassword !== confirmNewPassword} onChange={(event) => setConfirmNewPassword(event.target.value)} />
                        </Form.Group>



                        <br />
                        <button type="submit" className={'btn btn-primary'} onSubmit={onPasswordReset}>Submit</button>

                    </Form>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;
