function DownloadButton() {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <a href="https://apps.apple.com/be/app/naise/id6443548816?l=nl">
                <button className="btn btn-light">
                    Download IOS
                </button>
            </a>
            <a href="https://play.google.com/store/apps/details?id=be.naise.naise">
                <button className="btn btn-light">
                    Download Android
                </button>
            </a>
        </div>
    );
}

export default DownloadButton;