import { Container, Row, Col, Image } from 'react-bootstrap';
import { Canvas, useLoader, extend, Object3DNode, useFrame } from "@react-three/fiber";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { Billboard, Edges, OrbitControls, PresentationControls, Stage, useGLTF, useTexture, Text, Text3D, ScrollControls, useScroll } from "@react-three/drei";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import gsap from "gsap";

import colors from '../../../colors';
import Scene from './Scene';
import Overlay from './Overlay';
import VerticalMenu from './Menu';
import { useRef, useState } from 'react';

function HomeBig() {
    const screenRef: any = useRef();

    return (
        <div style={{ height: '100vh' }}>
            <VerticalMenu />
            <Canvas camera={{ fov: 45 }} dpr={window.devicePixelRatio}>
                <ambientLight intensity={1} />
                <ScrollControls pages={5.15} damping={0.25} >
                    <Overlay screenRef={screenRef} />
                    <Scene screenRef={screenRef} />
                </ScrollControls>
            </Canvas>
        </div>
    );
}

export default HomeBig