import React from "react";
import { FiMenu, FiHome, FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';
import { FaTiktok } from "react-icons/fa";

export default class Footer extends React.Component {
    render() {
        return (
            <div style={{ height: '25vh', borderTop: '5px solid black', color: 'white' }}>
                <br />

                {/* Our social links */}
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <a href="https://www.facebook.com/Naise.be/">
                        <FiFacebook size='10vh' color="white" />
                    </a>
                    <a href="https://www.instagram.com/naise.be/">
                        <FiInstagram size='10vh' color="white" />
                    </a>
                    <a href="https://www.linkedin.com/company/naisebe/">
                        <FiLinkedin size='10vh' color="white" />
                    </a>
                    <a href="https://www.tiktok.com/@naise.be/">
                        <FaTiktok size='10vh' color="white" />
                    </a>
                </div>

                {/* Legal information */}
                <div style={{ display: 'flex', justifyContent: 'space-around', textAlign: 'center', color: 'white' }}>
                    <a href="https://tlnt20686-my.sharepoint.com/:b:/g/personal/info_naise_be1/ERagzfYoMRNOhhp0Q_gAvgcBUTaarM--2Or1_As1S7NHZA?e=GD3LxW">Terms of use</a>
                    <a href="https://tlnt20686-my.sharepoint.com/:b:/g/personal/info_naise_be1/EX_UD78xG1hLrXsMSRG1IdMBeQv-QnWSnY0on8M4BzHMbw?e=j9SQL1">Privacy policy</a>
                    <a href="https://tlnt20686-my.sharepoint.com/:b:/g/personal/info_naise_be1/ERAqqfneC4xKmZZ0ywfCrIwBsE0lwytotjA2uq8h6O23jw?e=RHpyGK">Competition rules</a>
                </div>

                {/* Copyrights */}
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <span>
                        © 2024 Copyright: Naise
                    </span>
                </div>
            </div>
        );
    }
}