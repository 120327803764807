import { extend, Object3DNode, useFrame } from "@react-three/fiber";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { Text,useScroll } from "@react-three/drei";
import gsap from "gsap";

import { useLayoutEffect, useRef} from 'react';
import { Model } from '../Iphone X';
extend({ TextGeometry })

declare module "@react-three/fiber" {
    interface ThreeElements {
        textGeometry: Object3DNode<TextGeometry, typeof TextGeometry>;
    }
}

function SceneSmall(props: any) {
    const ref: any = useRef();
    const tl = useRef<gsap.core.Timeline>();
    const topNaise: any = useRef();
    const bottomNaise: any = useRef();
    const phone: any = useRef();
    const scroll = useScroll();

    useFrame(() => {
        tl.current!.seek(scroll.offset * tl.current!.duration());
    });

    const pageDuration = 1 / 6;
    
    useLayoutEffect(() => {
        tl.current = gsap.timeline({ paused: true });

        tl.current.to(
            topNaise.current!.position,
            {
                duration: pageDuration / 5,
                x: -5,
            },
            "start"
        );

        tl.current.to(
            bottomNaise.current!.position,
            {
                duration: pageDuration / 5,
                x: 5,
            },
            "start"
        );

        tl.current.to(
            phone.current!.rotation,
            {
                duration: pageDuration / 5,
                y: -Math.PI / 2,
            },
            "start"
        );

        tl.current.to(
            phone.current!.position,
            {
                duration: pageDuration * 2 / 5,
                x: -0.65,
                y: 0.1,
            },
            "start_reposition"
        );

        tl.current.to(
            phone.current!.rotation,
            {
                duration: pageDuration * 2 / 5,
                y: Math.PI * 2 - Math.PI / 2,
            },
            "start_reset"
        );

        tl.current.to(
            phone.current!.position,
            {
                duration: pageDuration,
            },
            "screen_wait"
        );

        tl.current.to(
            phone.current!.position,
            {
                duration: pageDuration,
                z: 1,
            },
            "outro"
        );


        tl.current.to(
            phone.current!.position,
            {
                duration: pageDuration * 3 + pageDuration,
            },
            "block_4_wait"
        );

    }, [props.firstBlock]);

    return (
        <group ref={ref}>
            <Text ref={topNaise} fontSize={0.5} position={[0, 0.7, 0.8]} color={0x00000}>N{'\n'}A</Text>
            <Text ref={bottomNaise} fontSize={0.5} position={[0, -1.05, 0.8]} color={0x00000}>S{'\n'}E</Text>
            <Model screenRef={props.screenRef} phoneRef={phone} position={[-0.01, -0.1, 0.5]} rotation={[Math.PI, Math.PI / 2, Math.PI]} scale={4} />
        </group>
    );
}

export default SceneSmall