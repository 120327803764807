import { useState } from 'react';
import { FiMenu, FiHome } from 'react-icons/fi';


function MenuSmall() {
    const [menuOpen, setMenuOpen] = useState(() => false);
    const sayHello = () => {
        setMenuOpen(!menuOpen);
    }

    return (
        <div className="text-light" style={{ height: '7vh', width: '100vw', position: 'fixed', zIndex: 100 }}>
            <div style={{ marginTop: '1vw' }}>
                <div onClick={sayHello} style={{ position: 'absolute', zIndex: 102, marginLeft: '2.5vw' }} >
                    <FiMenu size='5vh' color="white" />
                </div>


                <div className={`box ${!menuOpen ? 'slideLeft' : ''}`} id='sub-menu-small' style={{ zIndex: 101, borderBottom: '5px solid black', height: '100%', width: '100%', position: 'absolute' }}>
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0, overflow: 'hidden', marginRight: '2.5vw' }}>
                        <li style={{ float: 'right' }}>
                            <a href='/'>

                                <FiHome size='5vh' color="white" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuSmall