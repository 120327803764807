import { Scroll, useScroll } from "@react-three/drei";
import { useState, useEffect } from "react";
import Footer from "./Footer";
import * as THREE from 'three'
import DownloadButton from "../DownloadButton";
import Block1 from "../Blocks/Block-1";
import Block2 from "../Blocks/Block-2";
import Block3 from "../Blocks/Block-3";
import Block4 from "../Blocks/Block-4";
import { useFrame } from "@react-three/fiber";

const Section = (props: any) => {
    return (
        <section
            id={props.id}
            style={{
                width: '100vw',
                height: props.height ? props.height : '100vh',
                display: 'flex'
            }}
        >
            <div style={{ alignSelf: 'flex-end' }}>
                <div className="p-5 rounded text-light" style={{ width: '100%' }}>
                    {props.children}
                </div>
            </div>

        </section>
    );
};

function OverlaySmall(props: any) {
    const scroll = useScroll();
    const [_, setScroll] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);

    useFrame(() => {
        setScroll(scroll.range(0, 1));
    });
    
    const screenshots = ['intro.png', 'block_1.png', 'block_2.png', 'block_3.png', 'block_4.png'];
    const screenTextures = screenshots.map((screenshot) => {
        let texture = new THREE.TextureLoader().load(`models/screenshots/${screenshot}`);
        texture.center = new THREE.Vector2(0.5, 0.5)
        texture.flipY = false;
        return texture;
    });

    useEffect(() => {
        const bodyRef = document.getElementsByTagName('body')[0];

        if (bodyRef) {
            // remove all classes
            bodyRef.classList.remove(
                'block-1-color',
                'block-2-color',
                'block-3-color',
                'block-4-color',
            )

            const pages = 6;
            const pagePart = 1 / pages;
            const offset = 2 * pagePart;

            if (scroll.offset > offset && scroll.offset < offset + pagePart) {

                bodyRef.classList.add('block-1-color')
            }
            if (scroll.offset > offset + pagePart && scroll.offset < offset + 2 * pagePart) {

                bodyRef.classList.add('block-2-color')
            }
            if (scroll.offset > offset + 2 * pagePart && scroll.offset < offset + 3 * pagePart) {

                bodyRef.classList.add('block-3-color')
            }
            if (scroll.offset > offset + 3 * pagePart && scroll.offset <= 1) {

                bodyRef.classList.add('block-4-color')
            }

            if (scroll.offset < 1 / 6 * 3 / 5) {
                if (currentIndex != 0) {
                    props.screenRef.current.material.emissiveMap = screenTextures[0]
                    setCurrentIndex(0);
                }
            } else {
                if (currentIndex != 1) {
                    // give random index to screentexture from 1 to 4
                    props.screenRef.current.material.emissiveMap = screenTextures[Math.floor(Math.random() * 4) + 1]
                    setCurrentIndex(1);
                }
            }

        }
    });

    return (
        <Scroll html>
            <div>
                <Section height={'200vh'}></Section>

                <Section>
                    <Block1 />
                </Section>

                <Section>
                    <Block2 />
                </Section>

                <Section>
                    <Block3 />
                </Section>

                <Section>
                    <Block4 />
                </Section>
            </div>

            <Footer />
        </Scroll>
    );
};

export default OverlaySmall