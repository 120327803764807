function Block2() {
    return (
        <div>
            <h1 className="font-semibold font-serif text-2xl">
                Event discovery
            </h1>
            <p>The safety aspect is not for everyone. Therefore, discover and stay up to date of events nearby. We have combined these aspects in one app so that everyone has the safety features within reach.</p>
            <br />
            <ul>
                <li>Latest event info at your fingertips.</li>
                <li>Interactive content about events you like.</li>
                <li>You can be the influencer by promoting events you really like.</li>
                <li>If you eventually need help, you already have the app.</li>
                <li>You can always help others and be an active bystander.</li>
            </ul>
            <br />
            <p style={{ fontStyle: 'italic' }}>Did you know that there are 7 festival apps in Belgium which are used for 3 days on average and are not used for the rest of the year?</p>
            <p style={{ fontWeight: 'bold' }}>Naise is your go-to-event app for the whole year around!</p>
        </div>
    )
}

export default Block2;