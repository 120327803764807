import { extend, Object3DNode, useFrame } from "@react-three/fiber";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { Text, useScroll } from "@react-three/drei";
import gsap from "gsap";

import { useLayoutEffect, useRef } from 'react';
import { Model } from '../Iphone X';

extend({ TextGeometry })


declare module "@react-three/fiber" {
    interface ThreeElements {
        textGeometry: Object3DNode<TextGeometry, typeof TextGeometry>;
    }
}

function Scene({ ...props }) {
    const ref: any = useRef();
    const tl = useRef<gsap.core.Timeline>();
    const leftNaise: any = useRef();
    const rightNaise: any = useRef();
    const phone: any = useRef();
    const scroll = useScroll();

    useFrame(() => {
        tl.current!.seek(scroll.offset * tl.current!.duration());
    });

    const pageDuration = 1 / 5.15;

    useLayoutEffect(() => {
        tl.current = gsap.timeline({ paused: true });

        tl.current.to(
            leftNaise.current!.position,
            {
                duration: pageDuration / 5,
                x: -5,
            },
            "start"
        );

        tl.current.to(
            rightNaise.current!.position,
            {
                duration: pageDuration / 5,
                x: 5,
            },
            "start"
        );

        tl.current.to(
            phone.current!.rotation,
            {
                duration: pageDuration / 5,
                y: -Math.PI / 1.8,
            },
            "start"
        );

        tl.current.to(
            phone.current!.position,
            {
                duration: pageDuration / 5,
                x: -0.15,
            },
            "start_zoom"
        );

        tl.current.to(
            phone.current!.position,
            {
                duration: pageDuration / 5,
                z: 0.1,
            },
            "start_side"
        );

        tl.current.to(
            phone.current.rotation,
            {
                duration: pageDuration * 2 / 5,
                y: Math.PI * 2 - Math.PI / 1.75,
            },
            "block_1"
        );

        tl.current.to(
            phone.current.position,
            {
                duration: pageDuration * 4 / 5,
            },
            "block_1_wait"
        );

        tl.current.to(
            phone.current!.rotation,
            {
                duration: pageDuration / 5,
                y: - Math.PI + Math.PI / 2.5,
            },
            "block_2"
        );

        tl.current.to(
            phone.current!.position,
            {
                duration: pageDuration * 4 / 5,
            },
            "block_2_wait"
        );


        tl.current.to(
            phone.current!.rotation,
            {
                duration: pageDuration / 5,
                y: Math.PI * 2 - Math.PI / 1.75,

            },
            "block_3"
        );


        tl.current.to(
            phone.current!.position,
            {
                duration: pageDuration * 4 / 5,
            },
            "block_3_wait"
        );

        tl.current.to(
            phone.current!.rotation,
            {
                duration: pageDuration / 5,
                y: - Math.PI + Math.PI / 2.5,
            },
            "block_4"
        );


        tl.current.to(
            phone.current!.position,
            {
                duration: pageDuration + pageDuration * 0.15,
                y: 0.025,
            },
            "block_4"
        );
    });

    return (
        <group ref={ref}>
            <Text ref={leftNaise} fontSize={2} position={[-1.35, -0.20, 1.1]} color={0x00000}>NA</Text>
            <Text ref={rightNaise} fontSize={2} position={[1.50, -0.20, 1.1]} color={0x00000}>SE</Text>
            <Model screenRef={props.screenRef} phoneRef={phone} position={[0.15, 0, 1.1]} rotation={[Math.PI, Math.PI / 2, Math.PI]} scale={9} />
        </group>
    );
}

export default Scene