
import React from 'react';
import SmallMenu from '../Home/Mobile/Menu';
import { Container } from 'react-bootstrap';
import Markdown from 'react-markdown';
import path from 'path';

function Policy() {
    // const markdown = '# Hi, *Pluto*!'
    // load markdown from a file
    const [markdown, setMarkdown] = React.useState('');
    React.useEffect(() => {
        // // Nodejs read file
        // // const filePath = path.join(__dirname, 'policy.md');
        // // setMarkdown("filePath");
        // setMarkdown(path.join(__dirname));
        // // console.log(filePath);

        fetch('policy.md')
            .then((response) => response.text())
            .then((text) => {
                setMarkdown(text);
            });
    }, []);


    return (
        <div>
            {/* <SmallMenu /> */}
            <div style={{ paddingTop: '5vh', paddingBottom: '5vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '80%' }}>
                    <Markdown className='text-light'>
                        {markdown}
                    </Markdown>
                </div>
            </div>
        </div>
    );
};

export default Policy;
