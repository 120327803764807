import { Canvas } from "@react-three/fiber";
import { ScrollControls } from "@react-three/drei";

import MenuSmall from './Menu';
import SceneSmall from './Scene';
import OverlaySmall from './Overlay';
import { useRef } from 'react';

function HomeSmall() {
    const screenRef: any = useRef();

    return (
        <div style={{ height: '100vh' }}>
            <MenuSmall />
            <Canvas camera={{ fov: 45 }} dpr={window.devicePixelRatio}>
                <ambientLight intensity={1} />
                <ScrollControls pages={6.25} damping={0.25} >
                    <OverlaySmall screenRef={screenRef} />
                    <SceneSmall screenRef={screenRef} />
                </ScrollControls>
            </Canvas>

        </div>
    );
}

export default HomeSmall