/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/models/iphone X.gltf --transform 
Files: public/models/iphone X.gltf [5.12KB] > D:\Projects\web\naise-web\iphone X-transformed.glb [155.41KB] (-2935%)
*/

import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'

export function Model({ phoneRef, position, rotation, scale, screenRef }) {
  const { nodes, materials } = useGLTF('models/iphone X.gltf')

  // const calizStella_mat = new THREE.MeshPhysicalMaterial({
  //   transparent: true,
  //   transmission: 10,
  //   envMapIntensity: 0.2,
  // });
  const calizStella_mat = new THREE.MeshPhysicalMaterial({  
    roughness: 1,
    transparent: true,
    transmission: 10,  
    // thickness: 0.1, // Add refraction!
  });

  // Load all the screenshots in /models/screenshots with TextureLoader
  const screenshots = ['intro.png', 'block_1.png', 'block_2.png', 'block_3.png', 'block_4.png'];

  const screenTextures = screenshots.map((screenshot) => {
    let texture = new THREE.TextureLoader().load(`models/screenshots/${screenshot}`);
    texture.center = new THREE.Vector2(0.5, 0.5)
    texture.flipY = false;
    return texture;
  });

  const textureMaterials = screenTextures.map((texture) => {
    return new THREE.MeshPhysicalMaterial({
      emissive: new THREE.Color(0.5, 0.5, 0.5),
      emissiveIntensity: 1,
      emissiveMap: texture,
      color: new THREE.Color(0, 0, 0),
      metalness: 0,
      roughness: 0
    });
  });
  

  return (
    <group dispose={null}>
      <group position={position} rotation={rotation} scale={scale}>
        <group ref={phoneRef}>
          <mesh geometry={nodes.Cube001.geometry} material={materials.IPHONE} />
          <mesh ref={screenRef} geometry={nodes.Cube001_1.geometry} material={textureMaterials[0]} />
          {/* <mesh geometry={nodes.Cube001_2.geometry} material={calizStella_mat} /> */}
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('models/iphone X.gltf')
