
import React from 'react';
import SmallMenu from '../Home/Mobile/Menu';
import { Container } from 'react-bootstrap';

function DeepLinksRedirect() {

    function isAndroid() {
        return /Android/i.test(navigator.userAgent);
    }

    function isIOS() {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    function getQueryVariable(variable: string) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }

        return null;
    }

    function openApp() {
        const dlPath = getQueryVariable('dl_path');

        if (!dlPath) {
            return;
        }
        // console.log(dlPath);

        if (isAndroid()) {
            const basePath = dlPath.replace('https://', '');

            window.location.href = `intent://${basePath}#Intent;scheme=https;package=be.naise.naise;end`;
        } else if (isIOS()) {
            window.location.href = dlPath;
        }
    }

    function downloadApp() {
        if (isAndroid()) {
            const androidUrl = 'https://play.google.com/store/apps/details?id=be.naise.naise';
            window.location.href = androidUrl;
        } else if (isIOS()) {
            const iosUrl = 'itms-apps://itunes.apple.com/app/be.naise.naise/6443548816';
            window.location.href = iosUrl;
        }
    }


    return (
        <div>
            <SmallMenu />
            <Container>

            </Container>
            <div style={{ paddingTop: '15vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '80%' }}>

                    <h2 style={{ paddingBottom: '2vh',}} className='text-light'>Open app</h2>
                    
                    <button onClick={() => openApp()} type="button" style={{ marginRight: '2vh'}} className={'btn btn-primary'} >Open App</button>

                    <button onClick={() => downloadApp()} type="button" className="btn btn-info">Download App</button>
                </div>
            </div>
        </div>
    );
};

export default DeepLinksRedirect;
