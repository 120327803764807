import { FiMenu, FiHome, FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';
import { FaTiktok } from "react-icons/fa";

import colors from '../../../colors';

function VerticalMenu() {

    return (
        <div className="text-light" style={{ height: '100vh', width: '4vw', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'fixed', zIndex: 100, borderRight: '5px solid black' }}>
            <div style={{ marginTop: '1vw', display: 'flex', justifyContent: 'center' }}>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {/* <li style={{ marginBottom: '1vw' }}>
                        <FiMenu size='2.5vw' color="white" />
                    </li> */}
                    <li style={{ marginBottom: '1vw' }}>
                        <a href='/'>
                            <FiHome size='2.5vw' color="white" />
                        </a>
                    </li>
                </ul>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li style={{ marginBottom: '1vw' }}>
                        <a href="https://www.facebook.com/Naise.be/">
                            <FiFacebook size='1.5vw' color="white" />
                        </a>
                    </li>
                    <li style={{ marginBottom: '1vw' }}>
                        <a href="https://www.instagram.com/naise.be/">
                            <FiInstagram size='1.5vw' color="white" />
                        </a>
                    </li>
                    <li style={{ marginBottom: '1vw' }}>
                        <a href="https://www.linkedin.com/company/naisebe/">
                            <FiLinkedin size='1.5vw' color="white" />
                        </a>
                    </li>
                    <li style={{ marginBottom: '1vw' }}>
                        <a href="https://www.tiktok.com/@naise.be/">
                            <FaTiktok size='1.5vw' color="white" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default VerticalMenu