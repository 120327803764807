function Block3() {
    return (
        <div>
            <h1 className="font-semibold font-serif text-2xl">
                How to contact the event organiser?
            </h1>
            <p>You can only contact an organsation if you are <span style={{ fontWeight: 'bold' }}>Checked-In</span> at their event. Make sure to do so when you arrive.</p>
            <br />

            <ul>
                <li>Open the app on the map.</li>
                <li>Press the alert icon on the right bottom corner.</li>
                <li>Select the topic you need assistance on.</li>
                <li>Press send to organisation.</li>
            </ul>

            <br />

            <p style={{ fontStyle: 'italic' }}>Don't want to contact the organisation but people you know like friends or family?</p>
            <p style={{ fontWeight: 'bold' }}>You can always contact your personal contacts all at once even if they don't have the app. This feature also works when you are NOT at an event.</p>
        </div>
    )
}

export default Block3;