import { Scroll, useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useState, useEffect } from "react";
import * as THREE from 'three'
import DownloadButton from "../DownloadButton";
import Footer from "./Footer";
import Block1 from "../Blocks/Block-1";
import Block2 from "../Blocks/Block-2";
import Block4 from "../Blocks/Block-4";
import Block3 from "../Blocks/Block-3";

const Section = (props: any) => {
    return (
        <section
            className="d-flex align-items-center justify-content-center"
            style={{
                float: 'left',
                opacity: props.opacity,
                width: '60%',
                height: '100vh',
            }}
        >
            <div className="p-5 rounded text-light" style={{ width: '60%' }}>
                {props.children}
            </div>
        </section>
    );
};

function Overlay({ ...props }) {
    const scroll = useScroll();
    const [_, setScroll] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);

    useFrame(() => {
        setScroll(scroll.range(0, 1));
    });

    const screenshots = ['intro.png', 'block_1.png', 'block_2.png', 'block_3.png', 'block_4.png'];
    const screenTextures = screenshots.map((screenshot) => {
        let texture = new THREE.TextureLoader().load(`models/screenshots/${screenshot}`);
        texture.center = new THREE.Vector2(0.5, 0.5)
        texture.flipY = false;
        return texture;
    });

    const pageDuration = 1 / 5.15;

    useEffect(() => {
        const bodyRef = document.getElementsByTagName('body')[0];

        if (bodyRef) {
            bodyRef.classList.remove(
                'block-1-color',
                'block-2-color',
                'block-3-color',
                'block-4-color',
            )
            if (scroll.offset > 0.1 && scroll.offset < 0.4) {
                bodyRef.classList.add('block-1-color')
            }
            if (scroll.offset > 0.4 && scroll.offset < 0.6) {
                bodyRef.classList.add('block-2-color')
            }
            if (scroll.offset > 0.6 && scroll.offset < 0.8) {
                bodyRef.classList.add('block-3-color')
            }
            if (scroll.offset > 0.8 && scroll.offset <= 1) {
                bodyRef.classList.add('block-4-color')
            }


            if (scroll.offset < pageDuration * 4 / 5) {
                if (currentIndex != 0) {
                    props.screenRef.current.material.emissiveMap = screenTextures[0]
                    setCurrentIndex(0);
                }
            } else if (scroll.offset > pageDuration * 4 / 5 && scroll.offset <= pageDuration * 2) {
                if (currentIndex != 1) {
                    props.screenRef.current.material.emissiveMap = screenTextures[1]
                    setCurrentIndex(1);
                }
            } else if (scroll.offset > pageDuration * 2 && scroll.offset <= pageDuration * 3) {
                if (currentIndex != 2) {
                    props.screenRef.current.material.emissiveMap = screenTextures[2]
                    setCurrentIndex(2);
                }
            } else if (scroll.offset > pageDuration * 3 && scroll.offset <= pageDuration * 4) {
                if (currentIndex != 3) {
                    props.screenRef.current.material.emissiveMap = screenTextures[3]
                    setCurrentIndex(3);
                }
            } else if (scroll.offset > pageDuration * 4) {
                if (currentIndex != 4) {
                    props.screenRef.current.material.emissiveMap = screenTextures[4]
                    setCurrentIndex(4);
                }
            }
        }
    });

    return (
        <Scroll html>
            <div>
                <Section></Section>

                <Section>
                    <Block1 />
                </Section>

                <Section>
                    <Block2 />
                </Section>

                <Section>
                    <Block3 />
                </Section>

                <Section>
                    <Block4 />
                </Section>

                <Footer />
            </div>
        </Scroll>
    );
};

export default Overlay