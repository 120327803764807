function Block4() {
    return (
        <div>
            <h1 className="font-semibold font-serif text-2xl">
                Transforming Safety & Care
            </h1>
            <p>Prevention, assistance in the moment and aftercare. With Naise we take care of you at any moment. All our info is backed by our trusted partner Sensoa.</p>
            <br />

            <ul>
                <li>Prevention: Interactive info on how to handle incidents.</li>
                <li>Assitance in the moment: Create an alert and get help instantly.</li>
                <li>Aftercare: At the event, in a care center nearby or online. We got the right care for you.</li>
            </ul>
        </div>
    )
}

export default Block4;