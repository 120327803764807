import '../App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import PasswordReset from './PasswordReset/PasswordReset';
import Home from './Home/Home';
import Policy from './Policy/Policy';
import DeepLinksRedirect from './DeepLinksRedirect/DeepLinksRedirect';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/policy" element={< Policy />} />
        <Route path="/deeplinks" element={< DeepLinksRedirect />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
