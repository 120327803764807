function Footer() {
    return (
        <div style={{ height: '15vh', width: '96vw', borderTop: '5px solid black', float: 'right', color: 'white' }}>
            <br />

            {/* Legal information */}
            <div style={{ display: 'flex', justifyContent: 'space-around', textAlign: 'center', color: 'white' }}>
                <a href="https://tlnt20686-my.sharepoint.com/:b:/g/personal/info_naise_be1/ERagzfYoMRNOhhp0Q_gAvgcBUTaarM--2Or1_As1S7NHZA?e=GD3LxW">Terms of use</a>
                <a href="https://tlnt20686-my.sharepoint.com/:b:/g/personal/info_naise_be1/EX_UD78xG1hLrXsMSRG1IdMBeQv-QnWSnY0on8M4BzHMbw?e=j9SQL1">Privacy policy</a>
                <a href="https://tlnt20686-my.sharepoint.com/:b:/g/personal/info_naise_be1/ERAqqfneC4xKmZZ0ywfCrIwBsE0lwytotjA2uq8h6O23jw?e=RHpyGK">Competition rules</a>
            </div>

            <br />

            {/* Copyrights */}
            <div style={{ width: '100%', textAlign: 'center' }}>
                <span>
                    © 2024 Copyright: Naise
                </span>
            </div>
        </div>
    );
}

export default Footer;