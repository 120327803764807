import DownloadButton from "../DownloadButton";

function Block1() {
    return (
        <div>
            <h1 className="font-semibold font-serif text-2xl">
                Going out Safe can be Naise!
            </h1>
            <p>Witnessed Harassment, need medical assistance at and event? </p>
            <br />
            <p style={{ fontWeight: "bold" }}>Stop searching and contact the event organiser directly with Naise!</p>

            <ul>
                <li>Instant connection with the organisation such as first aid, security, safe space, etc.</li>
                <li>When notified the organisation can locate you and assist you wherever you are.</li>
                <li>Get aftercare, physical-or digitally (Sensoa).</li>
                <li>Help others easily as active bystander.</li>
                <li>It is <span style={{ fontWeight: 'bold' }}>free for everyone</span>, just as safety should be.</li>
            </ul>

            <br />

            <p style={{ fontStyle: 'italic' }}>Never witnessed harassment or needed medical assistance?</p>
            <p style={{ fontWeight: 'bold' }}>Scroll down to see why you still need it!</p>

            <DownloadButton />
        </div>
    )
}

export default Block1;