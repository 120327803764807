import MediaQuery from "react-responsive";
import HomeBig from "./Web/HomeScreen";
import HomeSmall from "./Mobile/HomeScreen";

function Home(props: any) {

    return (
        <div >
            <MediaQuery minWidth={1500}>
                <HomeBig />
            </MediaQuery>

            <MediaQuery maxWidth={1500}>
                <HomeSmall />
            </MediaQuery>
        </div>
    );
}

export default Home